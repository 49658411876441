"use client";
import Link from "next/link";
import { Suspense } from "react";

import { Heading } from "@chakra-ui/react";
import { SignIn } from "@clerk/nextjs";

export const SignInScreen = () => (
	<div className="flex h-screen min-h-96 flex-row items-center justify-center p-0 lg:p-6">
		<div className="fixed left-4 top-8 z-20 lg:left-8">
			<Heading
				as="h4"
				fontSize="24px"
				fontWeight={800}
				lineHeight="28px"
				className="from-magenta-300 mb-0  block bg-gradient-to-r via-purple-500 to-purple-600 bg-clip-text font-extrabold text-transparent lg:hidden xl:block"
			>
				<Link href="/">PintLabs</Link>
			</Heading>
		</div>
		<div className="min-w-xl scrollbar-hide relative flex h-full w-full flex-col items-center justify-center overflow-auto bg-white px-4 lg:w-1/2 lg:px-0">
			<div className="flex w-full max-w-sm flex-col gap-y-4">
				<Heading as="h1" fontSize="3xl" fontWeight={800} className="mb-0">
					Log In
				</Heading>
				<p className="text-md mb-2 font-semibold text-gray-300">
					Welcome back! Please log in to access the lab.
				</p>
				<Suspense>
					<SignIn />
				</Suspense>
			</div>
			<div className="absolute bottom-0 left-0 w-full text-center">
				By logging in, you agree to our{" "}
				<Link
					href="https://www.brewlogix.com/privacy-policy/"
					className="underline"
				>
					Privacy Policy
				</Link>
				.
			</div>
		</div>
		<div className="bg-magenta-800 relative mx-auto hidden h-[calc(100vh-3rem)] min-h-96 w-1/2 flex-col items-center justify-center overflow-hidden rounded lg:flex">
			<div
				className="bg-magenta-600 absolute right-[-72rem] top-[-115%]  h-[120rem] w-[120rem] rounded-full shadow-xl"
				style={{
					transform: "matrix(0.59, 0.64, -1.15, 0.43, 0, 0)",
				}}
			></div>

			<div className="bg-magenta-500 absolute right-[-70rem]  top-[-160%] h-[126rem] w-[126rem] rotate-[-30deg] rounded-full shadow-xl"></div>

			<div className="from-magenta-400 to-magenta-800 absolute bottom-0 left-0 right-0 top-0 flex h-full w-full bg-gradient-to-tr via-transparent bg-cover bg-center bg-no-repeat"></div>

			<div className="absolute bottom-0 left-0 right-0 top-0 flex h-full w-full flex-col items-center justify-center text-center">
				<div className="text-[8rem]">🧪</div>
			</div>
		</div>
	</div>
);
